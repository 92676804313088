.main-menu-wrapper {
  height: 100%;
  position: relative;
  border: 1px solid #ccd6f2;
  background-color: #dae2f8;
  border-radius: 5px;
  width: 6rem;
}

.main-menu-wrapper-doc {
  height: 100%;
  position: relative;
  border: 1px solid #ccd6f2;
  background-color: #dae2f8;
  border-radius: 5px;
  width: 10rem;
}

.main-menu-btn {
  height: 100%;
  width: 100%;
  color: #002a45;
  display: flex;
  align-items: center;
  padding-inline: 15px;
  cursor: pointer;
  border-radius: 7px;

  box-shadow: 0 0 0px #002a45;

  transition: color 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

.main-menu-btn .btn {
  display: flex;
  justify-content: space-between;
  padding: 0.5rem;
  align-items: center;
  border: none;
  background-color: transparent;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.close-btn {
  border-radius: 50%;
  cursor: pointer;
  width: 1.5rem;
  height: 1.5rem;
  border: none;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #002a45;
  box-shadow: 0 0 5px #002a45;
}

/* .main-menu-btn:hover,
  .main-menu-btn:focus {
    box-shadow: 0 0 5px #002A45;
    color: #002A45;
  } */

.sub-menu-wrapper {
  max-width: calc(100vw - 20px - 20px);
  max-height: unset;
  position: absolute;
  background-color: transparent;
}

.sub-menu-inner-wrapper {
  /* margin-top: 14px; */
  /* margin-left: -100px; */
  position: relative;
}

.sub-menu-inner-wrapper-doc {
  /* margin-top: 14px; */
  margin-left: -20px;
  position: relative;
}

.container {
  border: 1px solid #002a45;
  border-radius: 5px;
  padding: 0.5rem;
  background-color: #eef1f9;
}

.container-header {
  padding: 0.2rem;
  display: flex;
  border-bottom: 1px solid #002a45;
  justify-content: space-between;
}

.input {
  width: 100%;
  border: none;
  border-bottom: 1px solid #002a45;
  /* border: 0px 0px 1px 0px; */
  background-color: transparent;
}

.input:focus {
  outline: none;
}
.sub-menu-list-wrapper {
  padding: 0.5rem;
  display: flex;
  max-height: 12rem;
  overflow-y: auto;
  gap: 0.5rem;
  /* border: 1px solid #002A45; */
  flex-direction: row;
  background-color: #eef1f9;
  border-radius: 10px;
  position: relative;
  z-index: 2;
}

.sub-menu-list-wrapper::-webkit-scrollbar {
  width: 2px; /* Customize scrollbar width */ /* Customize scrollbar background color */
}

.sub-menu-list-wrapper::-webkit-scrollbar-track {
  box-shadow: none;
  border-radius: 3px;
  background-color: white;
}

.sub-menu-list-wrapper::-webkit-scrollbar-thumb {
  outline: none;
  background-color: #002a45;
}


/* .sub-menu-inner-wrapper .arrow {
    position: absolute;
    background-color: black;
    z-index: 1;
    width: 15px;
    height: 15px;
    top: -1px;
    transform: rotate(45deg);
  } */

.sub-menu-list {
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: min-content;
}

.sub-menu-item {
  height: unset;
  width: max-content;
  color: #002a45;
  display: flex;
  align-items: center;
  border-radius: 7px;
  box-shadow: 0 0 0px #002a45;
  transition: color 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

.sub-menu-item-btn {
  padding-block: 8px;
  padding-inline: 15px;
  border: none;
  width: 100%;
  height: 100%;
  cursor: pointer;
  border: 1px solid transparent;
  background-color: transparent;
}

.sub-menu-item-btn-selected {
  padding-block: 8px;
  padding-inline: 15px;
  border: 1px solid #ccd6f2;
  width: 100%;
  height: 100%;
  cursor: pointer;
  border-radius: 5px;
  background-color: #dae2f8;
}

.sub-menu-item-btn:hover,
.sub-menu-item-btn:focus {
  border: 1px solid #ccd6f2;
  background-color: #dae2f8;
  border-radius: 5px;
}

.sub-menu-wrapper {
  top: 40px;
  opacity: 0;
  z-index: 998;
  pointer-events: none;
  transition: opacity 0.8s ease-out;
}
/* .main-menu-wrapper:hover .sub-menu-wrapper {
    display: block;
    opacity: 1;
    z-index: 999;
    pointer-events: auto;
  } */

.sub-menu-inner-wrapper {
  opacity: 0;
  animation: fadeInDown 0.4s both;
}

/* .main-menu-wrapper:hover .sub-menu-inner-wrapper {
    opacity: 1;
    animation: fadeInUp 0.8s both;
  } */

.d-none {
  display: none;
}

/* Translation Box CSS */
.textTrans-container {
  border: 1px solid #d1d9ef;
  transition: 0.5s;
  /* border-radius: 10px; */
  display: flex;
  width: 100%;
  flex-direction: column;
}

.textTrans-container-first-half {
  display: flex;
  flex-direction: column;
  min-height: 20.9rem;
  width: 100%;
}

.textTrans-container-rowone {
  flex-basis: 15%;
  border-bottom: 1px solid #d1d9ef;
  display: flex;
  width: 100%;
}

.textTrans-container-rowone-first-half {
  flex-basis: 50%;
  padding: 0.5rem;
  display: flex;
  gap: 0.5rem;
}

.textTrans-container-rowone-first-half .label {
  width: 6rem;
  border: 1px solid #ccd6f2;
  background-color: #dae2f8;
  border-radius: 5px;
  font-weight: bold;
  padding: 5px;
}

.textTrans-container-rowone-second-half {
  flex-basis: 50%;
  border-left: 1px solid #d1d9ef;
  background-color: #eef1f9;
  /* border-top-right-radius: 10px; */
  display: flex;
  width: 100%;
}

.textTrans-container-rowone-second-half .label-container {
  /* flex-basis: 50%; */
  padding: 0.5rem;
  display: flex;
  width: 100%;
  gap: 0.5rem;
}

.textTrans-container-rowone-second-half .label {
  width: 6rem;
  border: 1px solid #ccd6f2;
  background-color: #dae2f8;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  font-weight: bold;
  padding: 5px;
}

.textTrans-container-rowtwo {
  flex-basis: 85%;
  border-bottom: 1px solid #d1d9ef;
  display: flex;
  position: relative;
  height: 100%;
  width: 100%;
}

.textTrans-container-rowtwo-first-half {
  flex-basis: 50%;
  padding: 0.5rem 1.75rem 0.5rem 0.5rem;
  height: 100%;
  text-align: justify;
}

.textTrans-container-rowtwo-first-half-textArea-first {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: flex-start;
  color: #002a45;
  background-color: #fafafa;
  font-size: 1.2em;
  outline: none;
  resize: none;
  border: none;
}

.textTrans-container-rowtwo-first-half-textArea-second {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: flex-start;
  color: #002a45;
  background-color: #eef1f9;
  font-size: 1.2em;
  outline: none;
  resize: none;
  border: none;
}

.textTrans-container-rowtwo-first-half-textArea-first::-webkit-scrollbar {
  width: 2px; /* Customize scrollbar width */ /* Customize scrollbar background color */
}

.textTrans-container-rowtwo-first-half-textArea-first::-webkit-scrollbar-track {
  box-shadow: none;
  border-radius: 3px;
  background-color: white;
}

.textTrans-container-rowtwo-first-half-textArea-first::-webkit-scrollbar-thumb {
  outline: none;
  background-color: #002a45;
}

.textTrans-container-rowtwo-first-half-textArea-second::-webkit-scrollbar-track {
  box-shadow: none;
  border-radius: 3px;
  background-color: white;
}

.textTrans-container-rowtwo-first-half-textArea-second::-webkit-scrollbar-thumb {
  outline: none;
  background-color: #002a45;
}

.textTrans-container-rowtwo-first-half-textArea-second::-webkit-scrollbar {
  width: 2px; /* Customize scrollbar width */ /* Customize scrollbar background color */
}

.textTrans-container-rowtwo-second-half {
  flex-basis: 50%;
  position: relative;
  padding: 0.5rem 0.5rem 0.5rem 1.75rem;
  display: flex;
  flex-direction: column;
  height: 100%;
  border-left: 1px solid #d1d9ef;
  background-color: #eef1f9;
  color: #002a45;
}

.textTrans-container-rowtwo-second-half::-webkit-scrollbar {
  width: 2px; /* Customize scrollbar width */ /* Customize scrollbar background color */
}

.textTrans-container-rowtwo-second-half::-webkit-scrollbar-track {
  box-shadow: none;
  border-radius: 3px;
  background-color: white
}

.textTrans-container-rowtwo-second-half::-webkit-scrollbar-thumb {
  outline: none;
  background-color: #002a45;
}

.swap-btn {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 0.5rem;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: #dae2f8;
}

.textTrans-container-second-half {
  margin: 0rem 0.5rem;
  padding: 0.1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* border-bottom: 1px solid #ccd6f2; */
  cursor: pointer;
  position: relative;
}

.scrolLine {
  width: 5rem;
  position: absolute;
  bottom: 0;
  left: 0;
  height: 0.25rem;
  border-radius: 5px;
  background-color: #002a45;
  transition: 0.5s;
}
.textTrans-container-third-half-rowone {
  margin: 0rem 0.5rem;
  padding: 0.5rem;
  scroll-behavior: smooth;
  align-items: stretch;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 1rem;
  overflow-x: auto;
  overflow-y: hidden;
}

.textTrans-container-third-half-rowone::-webkit-scrollbar {
  width: 0px;
  height: 2px; /* Customize scrollbar width */ /* Customize scrollbar background color */
}

.textTrans-container-third-half-rowone::-webkit-scrollbar-track {
  box-shadow: none;
  border-radius: 3px;
  background-color: white;
}

.textTrans-container-third-half-rowone::-webkit-scrollbar-thumb {
  outline: none;
  background-color: #002a45;;
}

/* .box-container2-row1 ::-webkit-scrollbar-track {
  -webkit-box-shadow: none !important;
  background-color: transparent;
}

.box-container2-row1 ::-webkit-scrollbar {
  width: 3px !important;
  background-color: transparent;
}

.box-container2-row1 ::-webkit-scrollbar-thumb {
  background-color: transparent;
} */

/* history card */

.textTrans-history-container {
  flex-basis: 25%;
  flex-grow: 0;
  flex-shrink: 0;
  height: 8rem;
  cursor: pointer;
  max-width: 25%;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: 0.5rem;
  border: 1px solid #002a45;
  border-radius: 5px;
}

.textTrans-history-container-selected {
  flex-basis: 25%;
  flex-grow: 0;
  flex-shrink: 0;
  height: 8rem;
  cursor: pointer;
  max-width: 25%;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  background-color: #eef1f9;
  padding: 0.5rem;
  border: 1px solid #002a45;
  border-radius: 5px;
}

.textTrans-history-container-rowone {
  flex-basis: 33.33%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.textTrans-history-container-rowsecond {
  flex-basis: 33.33%;
  border-bottom: 1px solid #002a45;
  font-size: 00.79em;
  font-weight: bold;
  display: flex;
  align-items: center;
}

.textTrans-history-container-rowthird {
  flex-basis: 33.33%;
  font-size: 00.79em;
  display: flex;
  align-items: center;
  color: #afafaf;
}

.Card-label {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: max-content;
  font-size: 0.79em;
  border: 1px solid #ccd6f2;
  background-color: #dae2f8;
  border-radius: 5px;
  font-weight: bold;
  padding: 5px;
}

@keyframes fadeInUp {
  from {
    transform: translate3d(0, 30px, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

@keyframes fadeInDown {
  from {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }

  to {
    transform: translate3d(0, 20px, 0);
    opacity: 0;
  }
}
