.Translator-home {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  
  .Translator-home-button-Box {
    position: relative;
    display: flex;
    border-top: 1px solid #ccd6f2;
    border-left: 1px solid #ccd6f2;
    border-right: 1px solid #ccd6f2;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    background-color: #eef1f9;
    width: 100%;
    /* border: 1px solid #002A45; */
    /* box-shadow: 0px 4px 8px #00000029; */
  }
  
  .Translator-home-toggleBtn {
    padding: 1rem 2rem;
    cursor: pointer;
    width: 50%;
    font-size: 0.9rem;
    background: transparent;
    transition: color 0.5s;
    border: 0;
    outline: none;
    position: relative;
    display: flex;
    gap: 0.2rem;
    justify-content: center;
    align-items: center;
    /* text-align: center; */
  }
  
  .Translator-home-btn {
    left: 0;
    top: 0;
    position: absolute;
    width: 50%;
    height: 100%;
    background: #002a45;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    transition: 0.5s;
  }
  