* {
  font-family: 'Roboto', sans-serif;
}
*::-webkit-scrollbar {
  width: 12px;
}
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px #ccc;
  border-radius: 3px;
}
::-webkit-scrollbar-thumb {
  background: #ccc;
  outline: 1px solid #708090;
  border-radius: 3px;
}

/* include google font & icons */

@font-face {
  font-family: 'Material Icons' !important;
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/materialicons/v125/flUhRq6tzZclQEJ-Vdg-IuiaDsNc.woff2)
    format('woff2');
}

.material-icons {
  font-family: 'Material Icons' !important;
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: 'liga';
  -webkit-font-smoothing: antialiased;
}
