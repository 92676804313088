/* .my-custom-checkbox .rct-checkbox > label > span {
  /* adjust the font-size, height, and width properties to match your desired size */
/* font-size: 60px;
  height: 60px;
  width: 60px;
} */
.react-checkbox-tree .rct-checkbox {
  width: 20px !important;
  height: 20px !important;
}
.react-checkbox-tree .rct-icon {
  font-size: 20px !important;
}
