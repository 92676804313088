.fade-in {
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

.visible {
  opacity: 1;
}

.hidden {
  opacity: 0;
}

.btn-clicked {
  color: white;
  background-color: #dc3223;
  border-radius: 5px;
  max-width: '10rem';
  padding: 0.6rem 0.7rem;
  border: 0px;
  cursor: pointer;
}

.btn-No {
  color: rgb(0, 42, 69);
  background-color: #eef1f9;
  border-radius: 5px;
  max-width: '10rem';
  padding: 0.6rem 0.7rem;
  border: 0px;
  cursor: pointer;
}

.btn-No:hover {
  color: white;
  background-color: #dc3223;
}

.rex-icon:hover {
  color: white;
  background-color: #dc3223;
}

/* Text Analysis CSS Code */

.toolTip-LangCode {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #ccd6f2;
  background-color: #eef1f9;
  border-radius: 5px;
  padding: 5px;
  margin: 5px 5px;
}

.toolTip-Translation {
  display: flex;
  width: max-content;
  align-items: center;
  padding-top: 15px;
  padding-left: 15px;
}

/* Text analysis parent container */

.text-analysis-parent {
  display: flex;
  gap: 20px;
  width: 100%;
}
.text-analysis-parent-container {
  display: flex;
  width: 100%;
  gap: 20px;
}

.text-analysis-parent-container-child {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 10px;
  /* margin-bottom: 0.5rem; */
}

.text-analysis-parent-container-child-paper-1 {
  width: 100%;
  padding: 5px 20px 5px 6px;
  display: flex;
  gap: 10px;
  border: 0.5px solid #002a45;
  border-radius: 22px;
  box-shadow: 0px 4px 8px #00000029;
}

.text-analysis-parent-container-child-paper-2 {
  width: 100%;
  padding-left: 1rem;
  display: flex;
  gap: 10px;
  border: 0.5px solid #002a45;
  border-radius: 22px;
  box-shadow: 0px 4px 8px #00000029;
}
/* Text Analysis CSS for Filter Button

.filter-Container {
  display: flex;
  flex-direction: row;
  gap: 10px;
  padding: 0.8rem;
  max-height: 8rem;
  width: 60rem;
}

.filter-child1 {
  flex-basis: 25%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.filter-child1-toogle {
  display: flex;
  justify-content: center;
  align-items: center;
}

.filter-child2 {
  flex-basis: 25%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.filter-child2-slider-container {
  display: flex;
  flex-direction: column;
  gap: 2px;
  font-weight: bold;
  font-size: 0.9rem;
}

.filter-child2-slider-container-child1 {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.2rem;
}

.filter-child2-slider-container-child1-stone {
  border: 1px solid #002a45;
  padding: 0.1rem;
  color: #002a45;
  font-weight: bold;
  text-align: center;
  font-size: 0.7rem;
} */

/* filter css for similarity search */

.simi-Filter-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.simi-Filter-container-child {
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 10px;
}

.simi-Filter-container-child-element {
  flex-basis: 25%;
}

.simi-Filter-container-slider-parent {
  display: flex;
  width: 100%;
  flex-direction: row;
  gap: 10px;
}

.simi-Filter-container-slider-parent-container {
  flex-basis: 37.5%;
  display: flex;
  flex-direction: column;
  gap: 2px;
  font-weight: bold;
  font-size: 0.9rem;
}

/* Rex filter Container */

.rex-filter-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.rex-filter-container-child {
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 10px;
}

.rex-filter-container-child-element {
  flex-basis: 25%;
}

.rex-filter-container-slider-parent {
  display: flex;
  width: 100%;
  flex-direction: row;
  gap: 10px;
}

.rex-filter-container-slider-parent-container {
  flex-basis: 50%;
  display: flex;
  flex-direction: column;
  gap: 2px;
  font-weight: bold;
  font-size: 0.9rem;
}

.rex-filter-container-half-typo {
  flex-basis: 50%;
  display: flex;
  gap: 10px;
  font-weight: bold;
  font-size: 0.9rem;
}

.rex-filter-container-half-typo-half {
  flex-basis: 50%;
}

/* opearation List Botton Prent component */

.feature-list-parent {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.feature-list-parent-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #002a45;
  width: 100%;
}

/* opearation List Botton Prent component */

.feature-list-parent {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.feature-list-parent-container-workspace {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  align-items: center;
  color: #002a45;
  width: 100%;
  margin-top: 10px;
}

/* result Continer */

.result-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
