.fileTrans-container {
  display: flex;
  flex-direction: column;
  /* border-radius: 10px; */
  transition: 0.5s;
  border: 1px solid #d1d9ef;
}

.fileTrans-container-rowone {
  display: flex;
  justify-content: space-between;
  background-color: #eef1f9;
  /* border-top-left-radius: 10px;
  border-top-right-radius: 10px; */
  padding: 10px 20px;
  border-bottom: 1px solid #d1d9ef;
}

.fileTrans-container-rowone-menu {
  display: flex;
  gap: 0.5rem;
}

.fileTrans-container-rowsecond {
  padding: 10px 20px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  border-bottom: 1px solid #d1d9ef;
}
.fileTrans-container-rowsecond-top-bar {
  position: relative;
  border-bottom: 1px solid #d1d9ef
}

.top-bar-file-line {
  width: 5rem;
  position: absolute;
  bottom: 0;
  left: 0;
  height: 0.25rem;
  border-radius: 5px;
  background-color: #002a45;
  transition: 0.5s;
}
.fileTrans-rowsecond-process-container {
  display: flex;
  gap: 10px;
  justify-content: center;
  align-items: center;
}

.fileTrans-process-card {
  display: flex;
  flex-basis: 25%;
  gap: 0.5rem;
  flex-direction: column;
  padding: 0.5rem;
}

.fileTrans-process-card .process-file-name {
  padding: 1rem;
  display: flex;
  gap: 0.5rem;
  align-items: center;
  border-radius: 5px;
  background-color: #d1d9ef;
}

.process-file-name .pocess-file-name-file-size {
  display: flex;
  flex-direction: column;
  gap: 0.3rem;
}

.fileTrans-process-card .process-file-loading {
  display: flex;
  gap: 0.5rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.fileTrans-container-rowthird {
  display: flex;
  flex-direction: column;
  padding: 0px 20px;
  /* justify-content: space-between;
  padding: 10px; */
}

.fileTrans-container-rowthird-header-up {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  padding: 0.5rem 0rem;
}

.fileTrans-container-rowthird-header-down {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  padding: 0.5rem 0rem;
  border-bottom: 1px solid #d1d9ef;
}

.fileTrans-container-rowthird-header-first-half {
  display: flex;
  justify-content: center;
  gap: 0.5rem;
  align-items: center;
}

.fileTrans-container-rowthird-header-second-half {
  display: flex;
  justify-content: center;
  align-items: center;
}

.fileTrans-container-rowthird-History {
  padding: 10px;
}
