/* for all table sortingcontrol opacity*/
.MuiTableSortLabel-icon{
    opacity: 0.5 !important;
  }
  .MuiTable-root {
    width: 100%;
    display: table;
    border-spacing: 0;
    border-radius: 5px;
    opacity: 1;
}
.MuiTableCell-root{
  border-bottom: 1px solid #002A45 !important;
}