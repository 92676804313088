@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: 'AlstomRegular' !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

@font-face {
  font-family: 'AlstomRegular';
  src: local('AlstomRegular'), url('./assets/fonts/AlstomRegular.ttf') format('truetype');
  font-weight: normal;
}
