.circle-progress-container {
  width: 150px;
  height: 150px;
  position: relative;
}

.suvgEL {
  position: absolute;
  top: 0;
  left: 0;
  transform: rotate(-90deg);
  z-index: 1;
}

#circleEL {
  fill: transparent;
  stroke: #ccc;
  stroke-width: 10;
}

.progress-circle {
  /* fill: transparent;
    stroke: #ccc;
    stroke-width: 10; */
  fill: none;
  stroke: #002a45;
  stroke-width: 10;
  stroke-linecap: round;
  stroke-dasharray: var(--circumference) var(--circumference);
  stroke-dashoffset: calc(var(--circumference) - (var(--percentage) / 100) * var(--circumference));
  transition: stroke-dashoffset 0.5s ease-in-out;
}

.percentage-text {
  fill: #333;
  font-size: 14px;
  text-anchor: middle;
  /* Center horizontally */
}

.circleLabel {
  position: absolute;
  top: 50%;
  font-weight: bold;
  left: 50%;
  font-size: 1.5rem;
  transform: translate(-50%, -50%);
}

.linear-progress-container {
  width: 100%;
  max-width: 400px;
  display: flex;
  /* margin-top: 20px; */
}

.linear-progress-bar {
  width: 90%;
  height: 10px;
  background-color: #e0e0e0;
  border-radius: 10px;
  overflow: hidden;
}

.linear-progress-fill {
  height: 100%;
  background-color: #76c7c0;
  transition: width 0.5s ease-in-out;
}

.linearLabel {
  text-align: center;
  width: 4rem;
  /* margin-top: 5px; */
  font-size: 0.8rem;
}

/* @keyframes fillProgress {
    to {
      stroke-dasharray: var(--percentage) 100;
    }
  } */
