.highlight {
  background-color: yellow;
}

.orange {
  background-color: orange;
}

.highlighter .highlighted {
  background-color: #ffeaa7;
  color: #e17055;
  box-shadow: -3px 0 0 #ffeaa7, 3px 0 0 #ffeaa7;
}
