.buttonBox {
  position: relative;
  border: 1px solid #002a45;
  border-radius: 30px;
  /* border: 1px solid #002A45; */
  box-shadow: 0px 4px 8px #00000029;
}

.toggleBtn {
  padding: 10px 20px;
  cursor: pointer;
  width: 200px;
  font-size: 0.9rem;
  background: transparent;
  transition: color 0.5s;
  border: 0;
  outline: none;
  position: relative;
  text-align: center;
}

.btn {
  left: 0;
  top: 0;
  position: absolute;
  width: 200px;
  height: 100%;
  background: #002a45;
  border-radius: 30px;
  transition: 0.5s;
}

.buttonBox2 {
  width: 200px;
  position: relative;
  background-color: #eef1f9;
  border-radius: 30px;
  /* border: 1px solid #002A45; */
  box-shadow: 0px 4px 8px #00000029;
}

.toggleBtn2 {
  padding: 10px 20px;
  cursor: pointer;
  width: 100px;
  font-size: 0.9rem;
  background: transparent;
  transition: color 0.5s;
  border: 0;
  outline: none;
  position: relative;
  text-align: center;
}

.btn2 {
  left: 0;
  top: 0;
  position: absolute;
  width: 100px;
  height: 100%;
  background: #002a45;
  border-radius: 30px;
  transition: 0.5s;
}
