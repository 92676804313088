/* Text Analysis CSS for Filter Button */

.filter-Container {
  display: flex;
  flex-direction: row;
  gap: 10px;
  padding: 0.8rem;
  max-height: 8rem;
  width: 60rem;
}

.filter-child1 {
  flex-basis: 25%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.filter-child1-toogle {
  display: flex;
  justify-content: center;
  align-items: center;
}

.filter-child2 {
  flex-basis: 25%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.filter-child2-slider-container {
  display: flex;
  flex-direction: column;
  gap: 2px;
  font-weight: bold;
  font-size: 0.9rem;
}

.filter-child2-slider-container-child1 {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.2rem;
}

.filter-child2-slider-container-child1-stone {
  border: 1px solid #002a45;
  padding: 0.1rem;
  color: #002a45;
  font-weight: bold;
  text-align: center;
  font-size: 0.7rem;
}
